<template>
  <div>
    <b-card>
      <h5>Push Notification Management</h5>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
